<template>
  <div>
    <!-- <div class="card p-2">
      <div>
        <h3 class="font-weight-bolder text-center">
          ออกเลขล็อตเตอรี่ลาว
        </h3>
      </div>
      <div class="row">
        <div class="col-2" />
        <div class="col-8">
          <div>
            <label for="example-datepicker">เลือกงวดประจำวันที่</label>
            <b-form-datepicker
              id="example-datepicker"
              v-model="round"
              class="mb-1"
            />
          </div>
          <div>
            <label for="lotto">กรอกเลขล็อตเตอรี่ที่ออกรวงวัล</label>
            <input
              id="lotto"
              v-model="lotto"
              type="number"
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
              maxlength="6"
              class="form-control"
              placeholder="กรอกเลขล็อตเตอรี่"
            >
          </div>

          <div class="mt-2">
            <button
              class="btn btn-gradient-primary btn-block"
              @click="Check()"
            >
              ออกรางวัลแล้ว
            </button>
          </div>
        </div>
        <div class="col-2" />
      </div>
    </div> -->

    <div class="card">
      <b-table
        hover
        show-empty
        responsive
        :items="items"
        :fields="fields"
        class="mt-3"
      >
        <template #cell(id)="data">
          <router-link :to="{ name: '' }">
            {{ data.item.id }}
          </router-link>
        </template>

        <template #cell(depfirst_amount)="data">
          {{ data.item.depfirst_amount ? data.item.depfirst_amount : "0.00" }}
        </template>
        <template #cell(created_at)="data">
          ( {{ Time(data.item.created_at) }} ) {{ data.item.created_at }}
        </template>

        <template #empty="scope">
          <div class="p-2 text-center">
            <img
              src="/box.png"
              alt="empty"
              height="40px"
            > {{ scope.emptyFiltered ? 'No matching records found' : 'ไม่พบข้อมูล' }}
          </div>
        </template>

        <template #cell(status)="data">
          <span
            v-if="data.item.status === 'true'"
            class="badge badge-success badge-circle"
          >
            <feather-icon
              icon="CheckIcon"
              class="mr-25"
            />
            <span>ออกรางวัลแล้ว</span>
          </span>

          <span
            v-else
            class="badge badge-warning"
          >
            <feather-icon
              icon="XIcon"
              class="mr-25"
            />
            <span>รอออกรางวัล</span>
          </span>
        </template>

        <template #cell(action)>
          <div class="d-flex align-items-center">
            <b-button
              variant="primary"
              class="mr-1"
              size="sm"
            >
              <i class="fa fa-edit" />
            </b-button>
            <!-- <b-button
              variant="info"
              class="mr-1"
              size="sm"
            >
              เพื่อนที่แนะนำ
            </b-button> -->
          </div>
        </template>
      </b-table>
      <b-card-body
        class="d-flex justify-content-between flex-wrap pt-0 pl-1 pb-1"
      >
        <!-- page length -->
        <b-form-group
          label="Per Page"
          label-cols="8"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
            @change="GetList()"
          />
        </b-form-group>

        <!-- pagination -->
        <div class="pr-1 pt-1">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
            @input="GetList()"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </div>
  </div>
</template>

<script>
import {
  // BFormDatepicker,
  BTable, BCardBody, BPagination, BFormGroup, BFormSelect, BButton,
} from 'bootstrap-vue'
import moment from 'moment-timezone'

export default {
  name: 'Reward',
  components: {
    // BFormDatepicker,
    BTable,
    BCardBody,
    BPagination,
    BFormGroup,
    BFormSelect,
    BButton,
  },
  data() {
    return {
      lotto: '',
      round: '',
      list: [],
      fields: [
        { key: 'result', label: 'เลขที่ออก' },
        { key: 'status', label: 'สถานะ' },
        { key: 'roundth', label: 'งวดประจำวันที่' },
        {
          key: 'updated_at',
          label: 'เวลาที่ออก',
          formatter: value => moment(value)
            .tz('Asia/Bangkok')
            .format('DD/MM/YYYY HH:mm:ss'),
        },
        { key: 'resultbyname', label: 'ออกหวยโดย' },
        // { key: 'action', label: 'Action' },
      ],
      items: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    Check() {
      this.$swal({
        title: 'แจ้งเตือน ?',
        text: 'ยืนยันที่จะออกผลรางวัลหรือไม่ ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          this.show = true
          this.onSubmit()
        } else {
          this.show = false
          this.$swal('ยกเลิก', 'ยกเลิกการออกผลรางวัล !', 'error')
        }
      })
    },
    onSubmit() {
      if (this.lotto === '') {
        this.$swal({
          title: 'แจ้งเตือน ?',
          text: 'กรุณากรอกผลรางวัลที่จะออก',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonText: 'ตกลง',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })
        return
      }
      if (this.lotto.length !== 6) {
        this.$swal({
          title: 'แจ้งเตือน ?',
          text: 'กรุณากรอกผลรางวัลที่จะออกให้ครบ 6 หลัก',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonText: 'ตกลง',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })
        return
      }
      if (this.round === '') {
        this.$swal({
          title: 'แจ้งเตือน ?',
          text: 'กรุณาเลือกงวดที่จะออก',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonText: 'ตกลง',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })
        return
      }
      // console.log(this.round)
      const params = {
        round: this.round,
        result: this.lotto,
      }

      this.$http
        .post('https://api.sabaideelotto.com/api/lottery/reward', params)
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          // this.getList()
          this.$swal({
            title: 'ทำรายการสำเร็จ',
            text: 'ออกผลรางวัลสำเร็จ',
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: 'ตกลง',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          })
          // console.log(response.data)
          this.lotto = ''
          this.round = ''
          this.show = false
        })
        .catch(error => {
          console.log(error)
        })
    },
    getList() {
      // let index = 0
      const params = {
        page: this.currentPage,
        // perPage: this.perPage,
        // search_type: this.search_type,
        // search_val: this.search_val,
      }
      this.$http
        .post('https://api.sabaideelotto.com/api/lottery/getroundresult', params)
        .then(response => {
          // console.log(response.data)
          this.show = false
          this.items = response.data.data
          this.totalRows = response.data.total
        })
        .catch(error => console.log(error))
    },
  },
}
</script>

<style scoped>
.table {
    border-spacing: 0 15px;
    border-collapse: separate;
}
.table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
    scrollbar-width: none;
}
table {
    display: table;
    border-collapse: separate;
    box-sizing: border-box;
    text-indent: initial;
    border-spacing: 2px;
    border-color: grey;
}
thead {
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
}
tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
}
.table thead tr th, .table thead tr td, .table tbody tr th, .table tbody tr td {
    vertical-align: middle;
    border: none;
}
.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
}
.table td, .table th {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
}
th {
    text-align: inherit;
}
tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
}
.table tbody tr {
    box-shadow: 0 2px 10px rgb(0 0 0 / 10%);
    border-radius: 5px;
}
.table tbody tr td:nth-child(1) {
    border-radius: 5px 0 0 5px;
}
.table tbody tr td {
    background: #fff;
}
.table thead tr th, .table thead tr td, .table tbody tr th, .table tbody tr td {
    vertical-align: middle;
    border: none;
}
</style>
